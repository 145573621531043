import React from 'react';
import classNames from 'classnames';
import { package as PackagePropType } from 'lib/CustomPropTypes';

import { MultiStorylineContainer } from './MultiStorylineContainer';
import { setPackageDefaults } from './utils/packageDefaults';

import './styles.themed.scss';
import sassVariables from './sassVariables.module.scss';

export function MultiStoryline({ content }) {
  if (!content) return null;

  const {
    id,
    items,
    metadata,
  } = setPackageDefaults(content);
  if (!items || !items.length || !metadata) return null;

  const packageClass = sassVariables?.['package-outer-class'];

  return (
    <section
      data-packageid={id}
      className={classNames(
        'pkg',
        'multi-storyline',
        { [packageClass]: packageClass },
      )}
    >
      <MultiStorylineContainer content={content} />
    </section>
  );
}

MultiStoryline.propTypes = {
  content: PackagePropType.isRequired,
};
